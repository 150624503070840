<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'2'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>主流公司管理</span>
				</div>
				<div class="left-list-item" @click="leftClick('0')">
					<span>公司列表</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('1')">
					<span>衬板类型</span>
				</div>
			</div>
			<div class="right-next">
				<div class="date-table">
					<div class="title">
						<span>{{title}}</span>
					</div>
					<div class="edit-form">
						<div class="item">
							<div class="label">所属公司:</div>
							<el-select v-model="editForm.corporateid" clearable placeholder="请选择公司">
								<el-option v-for="item in options" :key="item.id" :label="item.company_name"
									:value="item.id">
								</el-option>
							</el-select>
						</div>
						<div class="item">
							<div class="label">衬板名称:</div>
							<el-input v-model="editForm.name" placeholder="衬板名称"></el-input>
						</div>
						<div class="item">
							<!--  @blur="onEditorBlur($event)" @focus="onEditorFocus($event)"
								@ready="onEditorReady($event)" @change="onEditorChange($event)" -->
							<div class="label">衬板详情:</div>
							<quill-editor class="editor" ref="myTextEditor" v-model="editForm.info"
								:options="editorOption">
							</quill-editor>
						</div>
						<div class="item">
							<div class="label">衬板顺序:</div>
							<el-input type="number" placeholder="衬板顺序" v-model="editForm.order"></el-input>
						</div>
						<div class="item submit">
							<el-button type="primary" @click="submitUpload">保存添加</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import leftList from "../../../components/leftList.vue";
	import topHeader from "../../../components/header.vue"
	export default {
		components: {
			leftList,
			topHeader
		},
		inject: ["reload"],
		data() {
			return {
				title: '新增衬板',
				isCollapse: false,
				uploadHeaders: {
					"Remember-Token": localStorage.getItem("rememberToken"),
				},
				options: [],
				editForm: {
					corporateid: '',
					name: '',
					info: '',
					order: null,
				},
				formDate: '',
				editorOption: {
					modules: {
						toolbar: [
							["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
							["blockquote", "code-block"], // 引用  代码块
							[{
								header: 1
							}, {
								header: 2
							}], // 1、2 级标题
							[{
								list: "ordered"
							}, {
								list: "bullet"
							}], // 有序、无序列表
							[{
								script: "sub"
							}, {
								script: "super"
							}], // 上标/下标
							[{
								indent: "-1"
							}, {
								indent: "+1"
							}], // 缩进
							// [{'direction': 'rtl'}],                         // 文本方向
							[{
								size: ["small", false, "large", "huge"]
							}], // 字体大小
							[{
								header: [1, 2, 3, 4, 5, 6, false]
							}], // 标题
							[{
								color: []
							}, {
								background: []
							}], // 字体颜色、字体背景颜色
							[{
								font: []
							}], // 字体种类
							[{
								align: []
							}], // 对齐方式
							["clean"], // 清除文本格式
							["link", "image"] // 链接、图片、视频
						], //工具菜单栏配置
					},
					placeholder: '请输入内容', //提示
					readyOnly: false, //是否只读
					theme: 'snow', //主题 snow/bubble
					syntax: true, //语法检测
				}
			};
		},
		created() {
			if (this.$route.query.id) {
				this.title = '编辑衬板';
				this.getinfo();
			}
			this.getCompanylist();
		},
		methods: {
			getinfo() {
				this.$get('liner/get_details', {
					id: this.$route.query.id
				}).then(res => {
					console.log(res);
					this.editForm.corporateid = res.data.data.companys_id;
					this.editForm.name = res.data.data.name;
					this.editForm.info = res.data.data.liner_details;
					this.editForm.order = res.data.data.order;
				})
			},
			submitUpload() {
				// let _this = this;
				if (this.editForm.corporateid == "") {
					this.$notify.error({
						title: "提示",
						message: "请选择所属公司！",
					});
					return false;
				}

				if (this.editForm.name == '') {
					this.$notify.error({
						title: "提示",
						message: "请填写衬板名称！",
					});
					return false;
				}
				if (this.editForm.info == '') {
					this.$notify.error({
						title: "提示",
						message: "请填写衬板详情！",
					});
					return false;
				}
				if (this.$route.query.id) {
					this.$post('upd/liner',{
						id:this.$route.query.id,
						company_id:this.editForm.corporateid,
						name:this.editForm.name,
						liner_details:this.editForm.info,
						order:this.editForm.order
					}).then(res =>{
						if (res.data.status == 200) {
							this.$notify({
								title: "提示",
								message: "操作成功！",
								type: "success",
							});
							this.$router.push("/linertype");
						} else {
							this.$notify.error({
								title: "提示",
								message: res.data.message,
							});
						}
					})
				} else {
					this.$post('add/liner',{
						company_id:this.editForm.corporateid,
						name:this.editForm.name,
						liner_details:this.editForm.info,
						order:this.editForm.order
					}).then(res =>{
						if (res.data.status == 200) {
							this.$notify({
								title: "提示",
								message: "操作成功！",
								type: "success",
							});
							this.$router.push("/linertype");
						} else {
							this.$notify.error({
								title: "提示",
								message: res.data.message,
							});
						}
					})
				}
			},
			getCompanylist(){
				this.$get('companies/get_list',{
					num:999
				}).then(res =>{
					console.log(res);
					this.options = res.data.data.data;
				})
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
			leftClick(index) {
				if (index == 0 && this.$route.path != "/statistics") {
					this.$router.push("/statistics");
				} else if (index == 1 && this.$route.path != "/linertype") {
					this.$router.push("/linertype");
				} else {
					this.reload();
				}
			},
		},
	};
</script>

<style lang="scss">
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none !important;
		margin: 0;
	}

	.quill-editor {
		margin-top: 20px;
		width: 80%;
	}

	.ql-editor {
		height: 230px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar-uploader {
		display: inline-block;
		vertical-align: top;
	}

	.avatar {
		width: 178px;
		height: 178px;
		// display: block;
	}

	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.date-table {
			margin-bottom: 20px;

			.operation {
				margin-top: 30px;

				.el-button {
					width: 100px;
				}
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 20px;
		}

		.edit-form {
			.submit {
				width: 80%;
				text-align: center;

				.el-button {
					width: 150px;
				}
			}

			.item {
				margin-top: 30px;

				.label {
					display: inline-block;
					vertical-align: middle;
					width: 120px;
				}

				.el-input {
					width: 230px;
				}
			}
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}
</style>
